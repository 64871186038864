import { createAsyncThunk } from '@reduxjs/toolkit';
import { ACTIONS_PREFIX } from '../constants';
import getCookieParams from '../selectors/getCookieParams';

const pureState = {
	claimFormData: null,
};

const saveClaimForm = createAsyncThunk(
	`${ACTIONS_PREFIX}/saveClaimForm`,
	async ({ values }, { getState }) => {
		const params = getCookieParams(getState());

		localStorage.setItem('claimFormData', JSON.stringify(values), {
			...params,
			expires: 364,
		});

		return { claimFormData: values };
	},
);

const reducerFactory = (builder) => {
	builder.addCase(saveClaimForm.fulfilled, (state, action) => {
		const { claimFormData } = action.payload;
		return {
			...state,
			claimFormData,
		};
	});
};

export default {
	reducerFactory,
	action: saveClaimForm,
	pureState,
};
